<template>
  <div class="pub_con">
    <div id="container" v-show="false"></div>
    <headTitle title="我要拍">
      <span @click="onSubmit">发布</span>
    </headTitle>

    <div class="pub_bdy">
      <textarea v-model="content" placeholder="想说点什么"></textarea>
      <van-radio-group
        style="padding: 10px 0"
        v-model="uploadType"
        direction="horizontal"
      >
        <van-radio name="img" @click="onUploadChange">图片</van-radio>
        <van-radio name="video" @click="onUploadChange">视频</van-radio>
        <!-- <van-radio name="audio" @click="onUploadChange">音频</van-radio> -->
      </van-radio-group>
      <div v-if="showImg">
        <van-uploader
          v-model="fileList"
          :max-count="9"
          :before-read="beforeRead"
          :after-read="afterRead"
          ><div class="upl_sty">
            <img src="../../assets/images/jiahao.png" alt="" /></div
        ></van-uploader>
      </div>
      <div v-else-if="showVideo">
        <van-uploader
          v-model="fileList"
          accept="video/*"
          :max-count="1"
          :before-read="beforeRead"
          :after-read="afterRead"
        >
          <div class="upl_sty">
            <img src="../../assets/images/jiahao.png" alt="" />
          </div>
        </van-uploader>
      </div>
      <div v-else-if="showAudio">
        <van-uploader
          v-model="fileList"
          accept="audio/*"
          :max-count="1"
          :before-read="beforeRead"
          :after-read="afterRead"
        >
          <div class="upl_sty">
            <img src="../../assets/images/jiahao.png" alt="" />
          </div>
        </van-uploader>
      </div>
    </div>
    <div class="pub_btm" @click="openPosition">
      <div class="pub_lft">
        <img src="../../assets/user/dinwei.png" alt="" />
        <span>{{ position ? position : "我在哪里" }}</span>
      </div>
      <img src="../../assets/user/rjt.png" alt="" />
    </div>
    <div class="pub_btm" @click="showPicker = true">
      <div class="pub_lft">
        <img src="../../assets/user/huati.png" alt="" />
        <span>{{ topic ? topic : "请选择话题" }}</span>
      </div>
      <img src="../../assets/user/rjt.png" alt="" />
    </div>

    <!-- 定位弹出层 -->
    <van-action-sheet
      v-model="showPosition"
      :round="false"
      :actions="actions"
      @select="onSelect"
    />
    <!-- 话题弹出层 -->
    <van-popup v-model="showPicker" position="bottom" :style="{ height: '100%' }">
      <div class="popHead">
        <div @click="clearRegin">清空</div>
        <div @click="showPicker = false">关闭</div>
      </div>
      <tree :list="topicList" :treetype="1"></tree>
    </van-popup>
  </div>
</template>

<script>
import { Toast } from "vant";
import tree from "@/components/massorder/tree";
import Bus from "@/bus";
import InteractionApi from "@/api/interaction";
import AMapLoader from "@amap/amap-jsapi-loader";

export default {
  data() {
    return {
      showPicker: false,
      topic: "",
      position: "",
      actions: [{ name: "不显示位置" }],
      showPosition: false,
      content: "", // 爆料内容
      uploadType: "img",
      showImg: true, // 默认上传图片
      showVideo: false, // 上传视频
      showAudio: false, // 上传音频
      fileList: [],
      topicList: [], // 话题分类
      ugcTypeId: "", // 分类id
      picUrlList: [],
      audioUrlList: [],
      videoUrlList: [],
      lng: 0, // 经度
      lat: 0, // 纬度
    };
  },
  components: {
    tree,
  },
  created() {
    this.initMap();
    Bus.$on("regionTree", (data) => {
      // console.log(data)
      this.showPicker = false;
      this.topic = data.ugcTypeName;
      this.ugcTypeId = data.id;
    });
  },
  mounted() {
    this.queryTopic();
  },
  methods: {
    // 地图初始化
    initMap() {
      let _this = this;
      AMapLoader.load({
        key: "44076d0b0b73976ffa71f8d120804b7a", // 申请好的Web端开发者Key，首次调用 load 时必填
        version: "2.0", // 指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
        plugins: ["AMap.Geolocation", "AMap.Geocoder"], // 需要使用的的插件列表，如比例尺'AMap.Scale'等
      })
        .then((AMap) => {
          var map = new AMap.Map("container", {
            zoom: 11, //级别
            center: [116.397428, 39.90923], //中心点坐标
            viewMode: "2D", //使用3D视图
          });
          AMap.plugin("AMap.Geolocation", function () {
            _this.geolocation = new AMap.Geolocation({
              // extensions: "all",
              enableHighAccuracy: true, //是否使用高精度定位，默认:true
              // timeout: 10000, //超过10秒后停止定位，默认：5s
              position: "RB", //定位按钮的停靠位置
              buttonOffset: new AMap.Pixel(10, 20), //定位按钮与设置的停靠位置的偏移量，默认：Pixel(10, 20)
              zoomToAccuracy: true, //定位成功后是否自动调整地图视野到定位点
            });
            map.addControl(_this.geolocation);
            // _this.timePosition();
          });
        })
        .catch((e) => {
          alert("地图调用失败");
        });
    },
    // 定位方法
    timePosition() {
      let _this = this;
      _this.actions = [{ name: "不显示位置" }];
      Toast.loading({
        message: "地图加载中...",
        forbidClick: true,
        duration: 0,
      });
      this.geolocation.getCurrentPosition(function (status, result) {
        if (status == "complete") {
          _this.lng = result.position.lng;
          _this.lat = result.position.lat;
          var geocoder = new window.AMap.Geocoder({
            radius: 1000,
            extensions: "all",
          });
          let lnglat = [_this.lng, _this.lat];
          geocoder.getAddress(lnglat, function (status, result) {
            if (status === "complete" && result.regeocode) {
              // _this.placeName = result.regeocode.formattedAddress;
              const obj = {
                name: result.regeocode.formattedAddress,
              };
              let tampArr = result.regeocode.pois.slice(0, 9);
              // console.log(tampArr);
              _this.actions.push(obj);
              _this.actions = _this.actions.concat(tampArr);
              _this.showPosition = true;
              // console.log(_this.actions);
            } else {
              console.log(result);
            }
          });
        } else {
          console.log(result);
        }
        Toast.clear();
      });
    },

    // 打开位置弹窗
    openPosition() {
      this.timePosition();
    },

    // 资源上传校验，图片每张不超过5M，视频不超过20M
    beforeRead(file) {
      const imgMaxSize = 5 * 1024 * 1024;
      const videoMaxSize = 20 * 1024 * 1024;
      // const audioMaxSize = 10 * 1024 * 1024;
      var arr = [];
      arr = file.type.split("/");
      const fileType = arr[0];
      // 获取后缀名
      let index = file.name.lastIndexOf(".");
      file.fileSuffix = file.name.substr(index + 1);
      // alert(JSON.stringify(file.fileSuffix));
      if (fileType !== "image" && fileType !== "video") {
        Toast("请上传图片或视频!");
        return false;
      }
      if (fileType === "video") {
        if (this.fileList.length) {
          Toast("视频不能与图片混合上传!");
          return false;
        }
        if (file.size > videoMaxSize) {
          Toast("视频大小不能超过20M");
          return false;
        }
      }
      // else if (fileType === "audio") {
      //   if (this.fileList.length) {
      //     Toast("音频不能与图片混合上传!");
      //     return false;
      //   }
      //   if (file.size > audioMaxSize) {
      //     Toast("音频大小不能超过10M");
      //     return false;
      //   }
      // }
      else {
        if (file.size > imgMaxSize) {
          Toast("单张图片大小不能超过5M");
          return false;
        }
      }
      return true;
    },

    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      file.fileName = file.file.name;
      this.noBtn = true;
      var formData = new FormData();
      formData.append("file", file.file);
      const params = {
        deviceNumber: "18678907656", // 设备号
        mediaSuffix: file.file.fileSuffix, // 文件后缀
      };
      InteractionApi.upLoadImg(formData, params)
        .then((res) => {
          // console.log(res);
          // alert(JSON.stringify(res.data));
          if (res.success) {
            file.url = res.data;
            file.status = "done";
            Toast("上传成功");
          }
        })
        .catch((err) => {
          console.log(err);
          file.status = "failed";
          file.message = "上传失败";
        });
    },

    // 查询话题分类
    queryTopic() {
      const params = {
        appName: this.appName,
        regionId: "",
      };
      const data = {
        page: 1,
        size: 20,
      };
      InteractionApi.getTopics(params, data).then((res) => {
        this.topicList = res.data;
        // console.log(res);
      });
    },

    // 发布爆料
    onSubmit() {
      if (!this.content) {
        Toast("请输入文字内容");
        return;
      }
      if (!this.topic) {
        Toast("请选择话题");
        return;
      }
      if (this.fileList.length) {
        // 有文件时操作
        if (this.uploadType === "img") {
          // 图片文件
          this.picUrlList = [];
          // console.log(this.fileList);
          for (let i in this.fileList) {
            const obj = {};
            obj.name = this.fileList[i].fileName;
            obj.url = this.fileList[i].url;
            this.picUrlList.push(obj);
          }
        } else if (this.uploadType === "video") {
          // 视频文件
          this.videoUrlList = [];
          for (let i in this.fileList) {
            const obj = {};
            obj.name = this.fileList[i].fileName;
            obj.url = this.fileList[i].url;
            this.videoUrlList.push(obj);
          }
        }
        // else if (this.uploadType === "audio") {
        //   // 音频文件
        //   this.audioUrlList = [];
        //   for (let i in this.fileList) {
        //     const obj = {};
        //     obj.name = this.fileList[i].fileName;
        //     obj.url = this.fileList[i].url;
        //     this.audioUrlList.push(obj);
        //   }
        // }
      }
      // console.log(this.picUrlList);
      // console.log(this.videoUrlList);
      let position = this.position;
      if (position === "不显示位置") {
        position = "";
      }
      const data = {
        textContent: this.content,
        memberId: JSON.parse(window.localStorage.userinfo).id,
        picUrlList: this.picUrlList,
        audioUrlList: this.audioUrlList,
        videoUrlList: this.videoUrlList,
        position: position,
        ugcTypeId: this.ugcTypeId,
      };
      InteractionApi.addNewInformation(data, { appName: this.appName }).then((res) => {
        if (res.success) {
          Toast("发布成功");
          this.topic = "";
          this.ugcTypeId = "";
          this.content = "";
          this.position = "";
          this.fileList = [];
        }
      });
    },

    // 选择位置
    onSelect(item) {
      this.position = item.name;
      this.showPosition = false;
    },

    // 选择话题
    onConfirm(val) {
      this.topic = val;
      this.showPicker = false;
    },

    // 清空话题
    clearRegin() {
      this.topic = "";
      this.showPicker = false;
    },

    // 资源上传类型改变
    onUploadChange() {
      this.fileList = [];
      this.picUrlList = [];
      this.videoUrlList = [];
      this.audioUrlList = [];
      if (this.uploadType === "img") {
        this.showImg = true;
        this.showVideo = false;
        // this.showAudio = false;
      } else if (this.uploadType === "video") {
        this.showVideo = true;
        this.showImg = false;
        // this.showAudio = false;
      }
      // else if (this.uploadType === "audio") {
      //   this.showAudio = true;
      //   this.showVideo = false;
      //   this.showImg = false;
      // }
    },
  },
};
</script>

<style lang="less" scoped>
.pub_con {
  padding: 0 10px;
  .pub_bdy {
    margin-top: 35px;
    padding: 10px 0;
    border-bottom: 1px solid #ececec;
    textarea {
      border: none;
      padding: 8px 0;
      resize: none;
      width: 100%;
      height: 60px;
    }
  }
  .pub_btm {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #ececec;
    .pub_lft {
      display: flex;
      align-items: center;
    }
    img {
      width: 23px;
      height: 23px;
      margin-right: 5px;
    }
  }
  .upl_sty {
    width: 80px;
    height: 80px;
    background: #f7f8fa;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 48px;
    }
  }
  .popHead {
    width: 100%;
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-content: center;
    border-bottom: 1px solid #ddd;
    > div {
      width: 50%;
      height: 100%;
      font-size: 14px;
      display: flex;
      justify-content: center;
      align-items: center;
      &:last-child {
        border-left: 1px solid #ddd;
        box-sizing: border-box;
      }
    }
  }
}
</style>
